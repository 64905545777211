import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import firebase from "firebase";
import Login from "./Login";
import PeopleView from "./PeopleView";
import ProjectView from "./ProjectView";
import NotFound from "./NotFound";
import { firebaseApp } from "../base";
import { dataService } from "../services/services.tsx";
import { getWeek, plusOneWeek, minusOneWeek, jumpToToday } from "../helpers";

/* COMPONENT LOCATION:  
  index.js
*/
class Router extends React.Component {
	state = {
		// creates the state for the user so we know when the user is signed in (null == not signed in)
		user: null,
		usersList: [],
		projects: "null",
		weekOf: getWeek(),
	};

	componentDidMount() {
		// checks to see if there is a user already signed in
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.authHandler({ user });
			}
		});
	}
	// switching an invited user to a signed in user
	setEmailIDToUserID = (emailID, userID) => {
		// getting an array of all the projects the users is assigned to when they log in
		const projectList = Object.getOwnPropertyNames(this.state.projects);
		// looping through those projects
		projectList.forEach((project) => {
			dataService.setEmailIDToUserID(userID, emailID, project);
		});
	};

	// creates the users in the realtime database, and initially stores just the users name
	initialStoreUser = () => {
		const userID = this.state.user.user.uid;
		// turing the email address into a valid firebase path ID as you can't have a path id with "." in them
		const emailID = this.state.user.user.email.split(".")[0];
		dataService.initialStoreUser(
			userID,
			emailID,
			{
				userID: this.state.user.user.uid,
				name: this.state.user.user.displayName,
				photoURL: this.state.user.user.photoURL,
				email: this.state.user.user.email,
				hoursPerDay: 8,
			},
			(exists, data) => {
				// only sets the project state if there were previously projects
				if (exists && data.projects !== undefined) {
					this.setState({ projects: data.projects });
					this.setEmailIDToUserID(emailID, userID);
				}
			}
		);
	};

	getUserList = () => {
		// Refactored
		dataService.getUsers((data) => {
			this.setState({
				// converts the data object into an array for easier access later when making people lists
				usersList: Object.keys(data).map((key) => {
					return { ...data[key], key };
				}),
			});
		});
	};

	// sets the state of the user on sign in
	// this is an async function because we have to wait for the reply from the server before well know if the sign in was successful
	authHandler = async (authData) => {
		this.setState({ user: authData, projects: null });
		this.initialStoreUser();
		this.getUserList();
	};
	authenticate = (provider) => {
		// the provider comes from the button this way this will work for more than one sign in method
		const authProvider = new firebase.auth[`${provider}AuthProvider`]();
		// signing the user in via a pop up window
		firebaseApp.auth().signInWithPopup(authProvider).then(this.authHandler);
	};
	// this function signs the user out, it is async for the same reason as above, just for signing out instead of signing in
	signOut = async () => {
		await firebase.auth().signOut();
		this.setState({ user: null });
	};

	// setting the week to a state so it can updated on button clicks
	state = {
		weekOf: getWeek(),
	};
	addWeek = () => {
		this.setState({ weekOf: plusOneWeek() });
	};
	subtractWeek = () => {
		this.setState({ weekOf: minusOneWeek() });
	};
	today = () => {
		this.setState({ weekOf: jumpToToday() });
	};

	render() {
		return (
			<BrowserRouter>
				<Switch>
					{/* this is the default path */}
					<Route exact path="/">
						{/* this checks if there is a user signed in or not, if there is then the page is redirected further down the root, if not then this login button is displayed */}
						{this.state.user ? (
							<Redirect to="/people" />
						) : (
							<>
								<p className="centered">
									You Shall NOT Pass (unless you sign in)
								</p>
								<Login
									// passing the props and necessary functions to the component
									authenticate={this.authenticate}
								/>
							</>
						)}
					</Route>
					<Route
						exact
						path="/people"
						render={(props) => {
							// this checks if there is a user signed in, but only on this specific path
							if (this.state.user) {
								return (
									<PeopleView
										{...props}
										signOut={this.signOut}
										userData={this.state.user}
										usersList={this.state.usersList}
										subtractWeek={this.subtractWeek}
										weekOf={this.state.weekOf}
										addWeek={this.addWeek}
										today={this.today}
									/>
								);
							} else {
								return (
									<>
										<p className="centered">
											You must to log in to view this page
										</p>
										<Login authenticate={this.authenticate} />
									</>
								);
							}
						}}
					/>
					<Route
						exact
						path="/projects"
						render={(props) => {
							// this checks if there is a user signed in, but only on this specific path
							if (this.state.user) {
								return (
									<ProjectView
										{...props}
										signOut={this.signOut}
										userData={this.state.user}
									/>
								);
							} else {
								return (
									<>
										<p className="centered">
											You must to log in to view this page
										</p>
										<Login authenticate={this.authenticate} />
									</>
								);
							}
						}}
					/>
					{/* this is a catch incase there is a path that is inputted that does not exist (THIS MUST BE LAST IN THE ROUTE! any <Route /> below it will not get checked as this one will override it) */}
					<Route component={NotFound} />
				</Switch>
			</BrowserRouter>
		);
	}
}

export default Router;
