import firebase from "firebase";
import {
	DatabaseStrategy,
	UserUpdateFunction,
	onAssignedProjectFunction,
	onSnapshotDataFunction,
	InitialUserData,
} from "./services";
import { getUserFolder } from "../helpers";

class RealTimeDatabaseStrategy implements DatabaseStrategy {
	// ===== get functions
	/**
	 *
	 * @param onUserUpdate a function that receives snapshot of user data from firebase
	 */
	public getUsers(onUserUpdate: UserUpdateFunction) {
		const users = firebase.database().ref("/users");
		users.on("value", (snapshot) => {
			const data = snapshot.val();
			onUserUpdate(data);
		});
	}
	public getUserDaysWorking(
		userID: string,
		projectName: string,
		onSnapshotData: onSnapshotDataFunction
	) {
		firebase
			.database()
			.ref("users/" + userID + "/projects/" + projectName + "/daysWorking/")
			.on("value", (snapshot) => {
				const exists = snapshot.exists();
				const data = snapshot.val();
				onSnapshotData(exists, data);
			});
	}
	public getProject(
		projectName: string,
		onSnapshotData: onSnapshotDataFunction
	) {
		firebase
			.database()
			.ref("projects/" + projectName)
			.once("value", (snapshot) => {
				const exists = snapshot.exists();
				const data = snapshot.val();
				onSnapshotData(exists, data);
			});
	}
	public getProjects(onSnapshotData: onSnapshotDataFunction) {
		firebase
			.database()
			.ref("projects/")
			.once("value", (snapshot) => {
				const exists = snapshot.exists();
				const data = snapshot.val();
				onSnapshotData(exists, data);
			});
	}
	/**
	 *
	 * @param userID
	 * @param projectName
	 * @param day this is the date that is stored for that dat in the database, stored as YYYY-DD-MM
	 * @param onSnapshotData
	 */
	public getHours(
		userID: string,
		projectName: string,
		day: string,
		onSnapshotData: onSnapshotDataFunction
	) {
		firebase
			.database()
			.ref(
				"users/" + userID + "/projects/" + projectName + "/daysWorking/" + day
			)
			.once("value", (snapshot) => {
				const exists = snapshot.exists();
				const data = snapshot.val();
				onSnapshotData(exists, data);
			});
	}

	// ===== set functions
	/**
	 *  this function assigns the user's name, email, google photo, and userID when they first sign in
	 *      it will also copy and replace all of an invited users data into their userID instead of their emailID
	 * @param userID
	 * @param emailID
	 * @param userData this is the data that is stored for every user. Their name, email, google photo, and userID
	 * @param onSnapshotData a function that receives as parameters: whether or not the snapshot exists, all the user's that has been previously stored to an invited user including any assigned projects
	 */
	public initialStoreUser(
		userID: string,
		emailID: string,
		userData: InitialUserData,
		onSnapshotData: onSnapshotDataFunction
	) {
		firebase
			.database()
			.ref("users/" + userID)
			.once("value", (snapshot) => {
				//checks firebase to see if the user already has data stored in it
				if (!snapshot.exists()) {
					//checks to see if they have been invited and already have data for tied to their email
					firebase
						.database()
						.ref("users/" + emailID)
						.once("value", (snapshot) => {
							const exists = snapshot.exists();
							const data = snapshot.val();
							// copies the data from the emailID and merges it with usersData from the google sign in
							firebase
								.database()
								.ref("users/" + userID)
								// sets the users initial data
								.set({ ...userData, ...data, name: userData.name });

							onSnapshotData(exists, data);
							// removes the emailID after that use signs in
							if (exists) {
								firebase
									.database()
									.ref("users/" + emailID)
									.remove();
							}
						});
				}
			});
	}
	public setEmailIDToUserID(
		userID: string,
		emailID: string,
		projectName: string
	) {
		firebase
			.database()
			.ref("projects/" + projectName + "/users/")
			.once("value", (snapshot) => {
				// takes the data that is under their emailID and copies it under their userID
				firebase
					.database()
					.ref("projects/" + projectName + "/users/")
					.update({ [userID]: snapshot.val()[emailID] });
				// deletes the emailID to prevent duplicates
				firebase
					.database()
					.ref("projects/" + projectName + "/users/" + emailID)
					.remove();
			});
	}
	public inviteUser(
		emailID: string,
		emailAddress: string,
		data: Object,
		usersList: Array<any>
	) {
		let userAlreadyExists = false;
		// setting a temporary storage location ion the database for the added user until they sign in themselves
		firebase
			.database()
			.ref("users/" + emailID)
			.once("value", (snapshot) => {
				// checks firebase to see if the user already has data stored in it
				if (!snapshot.exists()) {
					usersList.forEach((user) => {
						const userFolder = getUserFolder(user);
						firebase
							.database()
							.ref("users/" + userFolder)
							.once("value", (snapshot) => {
								if (snapshot.val().email === emailAddress) {
									userAlreadyExists = true;
								}
								return userAlreadyExists;
							});
						return userAlreadyExists;
					});
					if (!userAlreadyExists) {
						firebase
							.database()
							.ref("users/" + emailID)
							// sets the users initial data
							.set(data);
					}
				}
			});
	}

	// ===== update functions
	public updateDaysWorking(userID: string, projectName: string, data: Object) {
		firebase
			.database()
			.ref("users/" + userID + "/projects/" + projectName + "/daysWorking/")
			.update(data);
	}
	public updateHours(userID: string, data: Object) {
		firebase
			.database()
			.ref("users/" + userID)
			.update(data);
	}
	public updateUserAndProjectData(
		userID: string,
		projectName: string,
		newData: Object
	) {
		firebase
			.database()
			.ref("projects/" + projectName + "/users/" + userID)
			//updates the days that the users is working in the project main folder
			.update(newData);
		firebase
			.database()
			.ref("users/" + userID + "/projects/" + projectName)
			//updates the days that the users is working in the users main folder
			.update(newData);
	}
	public incrementUsersPerProject(projectName: string, incrementVal: number) {
		firebase
			.database()
			.ref("projects/" + projectName + "/numberOfUsers")
			.set(firebase.database.ServerValue.increment(incrementVal));
	}
	public updateProjects(newData: Object) {
		firebase
			.database()
			.ref("projects/")
			//updates the days that the users is working in the project main folder
			.update(newData);
	}
	public updateProject(projectName: string, newData: Object) {
		firebase
			.database()
			.ref("projects/" + projectName)
			.update(newData);
	}

	// ===== add functions
	public addProjectToUser(userID: string, projectName: string) {
		//this adds the project name to the user under the projects folder
		firebase
			.database()
			.ref("users/" + userID + "/projects/")
			.update({
				[`${projectName}`]: {
					daysWorking: {
						working: true,
					},
				},
			});
	}
	public addUserToProject(userID: string, projectName: string) {
		//this increments the number of users that are working on a project
		firebase
			.database()
			.ref("projects/" + projectName + "/numberOfUsers")
			.set(firebase.database.ServerValue.increment(1));
		//this add the selected user to the project under the users folder
		firebase
			.database()
			.ref("projects/" + projectName + "/users/")
			.update({
				[`${userID}`]: {
					daysWorking: {
						working: true,
					},
				},
			});
	}
	// ===== compare functions
	public checkUsersAssignedProjects(
		userID: string,
		projectName: string,
		onAssignedProject: onAssignedProjectFunction
	) {
		firebase
			.database()
			.ref("projects/" + projectName + "/users/" + userID)
			.once("value", (snapshot) => {
				const assigned = snapshot.exists();
				onAssignedProject(assigned, userID, projectName);
			});
	}

	// ===== delete functions
	public deleteUserAndProjectData(userID: string, projectName: string) {
		firebase
			.database()
			.ref("projects/" + projectName + "/users/" + userID)
			.remove();
		firebase
			.database()
			.ref("users/" + userID + "/projects/" + projectName)
			.remove();
	}

	public deleteProject(projectName: string) {
		firebase
			.database()
			.ref("projects/" + projectName)
			.remove();
	}
}
export default RealTimeDatabaseStrategy;
