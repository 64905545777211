import React from "react";
import ReactDOM from "react-dom";
import "./build/css/index.css";
import reportWebVitals from "./reportWebVitals";
import Router from "./components/Router";

ReactDOM.render(<Router />, document.querySelector("#root"));

/* If you want to start measuring performance in your app, pass a function 
    to log results (for example: reportWebVitals(console.log))
    or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals */
reportWebVitals();
