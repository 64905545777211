import React from "react";
import PropTypes from "prop-types"; //debugging tool
import WeekDates from "./WeekDates";
import { monthNames } from "../../arrays";
import { dayInWeek, getTodaysDate } from "../../helpers";
import { dayNames } from "../../arrays";

/* COMPONENT LOCATIONS:  
  PeopleView.js
*/
class Calendar extends React.Component {
	render() {
		const date = new Date();
		return (
			<>
				<div className="calendar-wrapper">
					<div className="date-info">
						<div className="date-navi">
							<button onClick={() => this.props.subtractWeek()}>{"<"}</button>
							<h3>Week of {this.props.weekOf}</h3>
							<button onClick={() => this.props.addWeek()}>{">"}</button>
						</div>
						<button className="today-btn" onClick={() => this.props.today()}>
							Today
						</button>
						<h1>{monthNames[date.getMonth()] + " " + date.getFullYear()}</h1>
					</div>
					<WeekDates />
				</div>
				<div className="grid">
					{dayNames.map((day, index) => (
						<div
							className={`day ${
								//comparing the full date to todays date and adding a class if they match
								dayInWeek(index)[1] === getTodaysDate() ? "today" : ""
							}`}
							key={day.name}
						></div>
					))}
				</div>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: Router/PeopleView or ProjectView/
		subtractWeek: PropTypes.func,
		// PROPS PATH: Router/PeopleView or ProjectView/
		addWeek: PropTypes.func,
		// PROPS PATH: Router/PeopleView or ProjectView/
		today: PropTypes.func,
	};
}

export default Calendar;
