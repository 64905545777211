import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types"; //debugging tool
import Profile from "./Profile";

/* COMPONENT LOCATIONS:  
  PeopleView.js
  ProjectView.js
*/
class Navigation extends React.Component {
	render() {
		return (
			<nav>
				<ul className="tabs">
					<li
						className={
							// checks to see if the page matches the expected path and sets classes accordingly
							`${this.props.path === "/people" ? "active-tab" : ""}`
						}
					>
						{/* this is just an <a></> but for react*/}
						<Link to="/people">People</Link>
					</li>
					<li
						className={`${this.props.path === "/projects" ? "active-tab" : ""}`}
					>
						<Link to="/projects">Projects</Link>
					</li>
				</ul>
				<Profile signOut={this.props.signOut} userData={this.props.userData} />
			</nav>
		);
	}
	static propTypes = {
		// PROPS PATH: Router/PeopleView or ProjectView/
		path: PropTypes.string,
		// PROPS PATH: Router/PeopleView or ProjectView/
		signOut: PropTypes.func,
		// PROPS PATH: Router/PeopleView or ProjectView/
		userData: PropTypes.object,
	};
}

export default Navigation;
