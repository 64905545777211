import React from "react";
import { CSSTransition } from "react-transition-group";
import { PropTypes } from "prop-types"; //debugging tool
import EditProjectForm from "../../forms/EditProjectForm";
import { dataService } from "../../../services/services";

/* COMPONENT LOCATIONS:  
  people-view/user/ListOfUserProjects.js
*/
class Project extends React.Component {
	state = {
		editForm: false,
		projectName: "",
	};

	componentDidMount() {
		this.getProjectName();
	}

	// this functions toggles the edit from and prevents scroll while the form is open
	toggleEditForm = () => {
		this.setState({ editForm: !this.state.editForm });
		if (!this.state.editForm) {
			document.querySelector(".people-list").style.overflowY = "hidden";
			// document.querySelector("html").style.overflowY = "hidden";
		} else {
			document.querySelector(".people-list").style.overflowY = "scroll";
			// document.querySelector("html").style.overflowY = "visible";
		}
	};
	// updates the database in both the "projects" and "users" routes for the days working
	addTime = (projectName, userID, dayDetails) => {
		dataService.updateUserAndProjectData(userID, projectName, {
			daysWorking: dayDetails,
		});
	};
	getProjectName = () => {
		dataService.getProject(this.props.project, (exists, data) => {
			if (exists) {
				this.setState({ projectName: data.name });
			}
		});
	};
	render() {
		return (
			<>
				<div className="project-name">
					<div
						// this is an empty div for opening the edit project form that sits behind the buttons
						className="hidden-clickable-area"
						onClick={() => this.toggleEditForm()}
					></div>

					<p>{this.state.projectName}</p>
					<button
						className="edit-btn"
						onClick={() => this.props.deleteProject(this.props.project)}
					>
						delete
					</button>
					<div className="absolute-position">
						{this.state.editForm ? (
							<div
								className="form-background"
								onClick={() => this.toggleEditForm()}
							></div>
						) : null}
						<CSSTransition
							in={this.state.editForm}
							timeout={{ enter: 250, exit: 250 }}
							classNames="form"
							unmountOnExit
						>
							<EditProjectForm
								user={this.props.user}
								name={this.props.project}
								toggleForm={this.toggleEditForm}
							/>
						</CSSTransition>
					</div>
				</div>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/ListOfUserProjects/
		deleteProject: PropTypes.func,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/ListOfUserProjects/
		user: PropTypes.object,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/ListOfUserProjects/
		project: PropTypes.string, // this is the project ID
	};
}

export default Project;
