import React from "react";
import { PropTypes } from "prop-types"; //debugging tool

/* COMPONENT LOCATIONS:  
  Router.js
*/
class Login extends React.Component {
	render() {
		return (
			<div className="centered">
				<button onClick={() => this.props.authenticate("Google")}>
					Login with Google
				</button>
			</div>
		);
	}
	static propTypes = {
		// PROPS PATH: Router/
		authenticate: PropTypes.func,
	};
}

export default Login;
