import React from "react";
import PropTypes from "prop-types"; //debugging tool
import { dataService } from "../../services/services";
import { getUserFolder } from "../../helpers";

/* COMPONENT LOCATIONS:  
  people-view/User.js
*/
class MaxHoursPerDayForm extends React.Component {
	//creates all the necessary refs via react default methods of createRef so we can use the input data again later
	state = { hoursPerDay: "", projectList: [] };
	// this function sets the maximum hours for the user to work per day
	setDailyHours = (e) => {
		//prevents refresh on form submission
		e.preventDefault();
		const numHours = Number.parseFloat(this.state.hoursPerDay);
		dataService.updateHours(getUserFolder(this.props.user), {
			hoursPerDay: numHours,
		});
		this.props.toggleForm("hoursForm");
	};
	render() {
		return (
			<div className="form form-small">
				<button
					onClick={() => this.props.toggleForm("hoursForm")}
					className="close-btn"
				>
					+
				</button>
				<form onSubmit={this.setDailyHours}>
					<input
						type="number"
						value={this.state.hoursPerDay}
						min={0}
						max={24}
						onChange={(e) => {
							this.setState({ hoursPerDay: e.target.value });
						}}
						placeholder={`Current hours per day: ${this.props.user.hoursPerDay}`}
						required
					/>

					<button type="submit">set hours per day</button>
				</form>
			</div>
		);
	}
	static propTypes = {
		// PROPS PATH: User/
		toggleForm: PropTypes.func,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/
		user: PropTypes.object,
	};
}

export default MaxHoursPerDayForm;
