//stats on saturday because thats how workamajig tracks your time
const dayNames = [
	{
		abr: "sat",
		name: "saturday",
	},
	{
		abr: "sun",
		name: "sunday",
	},
	{
		abr: "mon",
		name: "monday",
	},
	{
		abr: "tue",
		name: "tuesday",
	},
	{
		abr: "wed",
		name: "wednesday",
	},
	{
		abr: "thr",
		name: "thursday",
	},
	{
		abr: "fri",
		name: "friday",
	},
];

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export { dayNames, monthNames };
