import firebase from "firebase";

//creates and initializes firebase
const firebaseApp = firebase.initializeApp({
	apiKey: "AIzaSyBKBR-bKONxIyvR10IxxalHNCWuXT02F2Q",
	authDomain: "chronos-944ef.firebaseapp.com",
	projectId: "chronos-944ef",
	storageBucket: "chronos-944ef.appspot.com",
	messagingSenderId: "519732077974",
	appId: "1:519732077974:web:e85870ee342867fe1a29b7",
	measurementId: "G-GPGVSBVKHD",
});

//named export
export { firebaseApp };
