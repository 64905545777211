// ============================================================== Date Helpers
//getting todays date
const getTodaysDate = () => {
	const date = new Date();
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	return `${month}/${day}/${year}`;
};
//getting the day of the week, not the date but the number of the day, 1-7
const dayInWeek = (index) => {
	const date = new Date();
	//gets the date of that weeks saturday because that is how the calender is layed out
	const saturday =
		date.getDate() - date.getDay() + (date.getDay() === 6 ? 6 : -1);
	//adds 7 * increment that is the number of weeks that you are adding to the current week
	const week = new Date(date.setDate(saturday + 7 * increment));
	const day = new Date(week.setDate(week.getDate() + index));
	const month = week.getMonth() + 1;
	const year = week.getFullYear();
	//returns two dates as strings, the first is just "dd", and the second in the "mm/dd/yyyy" so the current day can be highlighted
	return [
		`${day.getDate()}`,
		`${month}/${day.getDate()}/${year}`,
		`${year}-${("0" + month).slice(-2)}-${("0" + day.getDate()).slice(-2)}`,
		`${month}/${day.getDate()}`,
		`${year}-${("0" + month).slice(-2)}-${("0" + saturday).slice(-2)}`,
	];
};
// getting the start of the week based on calendar layout (Starts on Saturday)
const getWeekStart = () => {
	const date = new Date();
	//gets the date of that weeks saturday because that is how the calender is layed out
	const saturday =
		date.getDate() - date.getDay() + (date.getDay() === 6 ? 6 : -1);
	//adds 7 * increment that is the number of weeks that you are adding to the current week
	const week = new Date(date.setDate(saturday + 7 * increment));
	const day = week.getDate();
	const month = week.getMonth() + 1;
	const year = week.getFullYear();
	return `${month}/${day}/${year}`;
};
const getWeekEnd = () => {
	const date = new Date();
	//gets the date of that weeks friday
	const saturday =
		date.getDate() - date.getDay() + (date.getDay() === 6 ? 6 : -1);
	const friday = saturday + 6;
	const week = new Date(date.setDate(friday + 7 * increment));
	const day = week.getDate();
	const month = week.getMonth() + 1;
	const year = week.getFullYear();
	return `${month}/${day}/${year}`;
};
//gets the start and end of the week and returns them as a part of a single string
const getWeek = () => {
	return `${getWeekStart()} - ${getWeekEnd()}`;
};

//lets the weeks be added or subtracted to
let increment = 0;
const plusOneWeek = () => {
	//adds a week to what is currently displayed, then returns the new week
	increment++;
	return getWeek();
};
const minusOneWeek = () => {
	//subtracts a week to what is currently displayed, then returns the new week
	increment--;
	return getWeek();
};
const jumpToToday = () => {
	increment = 0;
	return getWeek();
};
const getWeekDates = () => {
	// gets the current date
	const date = new Date();
	// gets the saturday for the week based of the current date
	const saturday =
		date.getDay() === 6
			? date
			: // sets the date to a new sate as what is passed in the setDate() returns milliseconds
			  new Date(date.setDate(date.getDate() - date.getDay() - 1));
	saturday.setDate(saturday.getDate() + increment * 7);
	const weekDay = new Date(saturday);
	//creates a array that will contain all the days of the currently viewed week
	let days = [];
	for (let i = 0; i < 7; i++) {
		//populates the array with the appropriate values making sure the day and month are always 2 digits
		const day = ("0" + weekDay.getDate()).slice(-2);
		const month = ("0" + (weekDay.getMonth() + 1)).slice(-2);
		const year = weekDay.getFullYear();
		days.push(`${year}-${month}-${day}`);
		weekDay.setDate(weekDay.getDate() + 1);
	}
	// console.log(days);
	//returns the array full of the dates
	return days;
};

// ============================================================== workload Helpers
const getWorkload = (dailyHours, hoursWorking) => {
	return Math.round(
		(Number.parseFloat(hoursWorking) / (Number.parseFloat(dailyHours) * 5)) *
			100
	);
};

// ============================================================== User Information Helpers
const getUserFolder = (user) => {
	return user.userID === null || user.userID === undefined
		? user.email.split(".")[0]
		: user.userID;
};
// ============================================================== Generated ID Helpers
const generateID = () => {
	// 27 characters
	const number = Math.floor(Math.random() * 9) + 1;
	let ID = number;
	for (let i = 0; i < 27; i++) {
		ID = `${ID + generateLetter()}`;
		if (i % 3 === 0) {
			ID = `${ID + `${Math.floor(Math.random() * 9) + 1}`}`;
		}
	}
	console.log(ID);
	return ID;
};
const generateLetter = () => {
	const number = Math.floor(Math.random() * 26);
	let letter;
	switch (number + 1) {
		case 1:
			letter = "a";
			break;
		case 2:
			letter = "b";
			break;
		case 3:
			letter = "c";
			break;
		case 4:
			letter = "d";
			break;
		case 5:
			letter = "e";
			break;
		case 6:
			letter = "f";
			break;
		case 7:
			letter = "g";
			break;
		case 8:
			letter = "h";
			break;
		case 9:
			letter = "i";
			break;
		case 10:
			letter = "j";
			break;
		case 11:
			letter = "k";
			break;
		case 12:
			letter = "l";
			break;
		case 13:
			letter = "m";
			break;
		case 14:
			letter = "n";
			break;
		case 15:
			letter = "o";
			break;
		case 16:
			letter = "p";
			break;
		case 17:
			letter = "q";
			break;
		case 18:
			letter = "r";
			break;
		case 19:
			letter = "s";
			break;
		case 20:
			letter = "t";
			break;
		case 21:
			letter = "u";
			break;
		case 22:
			letter = "v";
			break;
		case 23:
			letter = "w";
			break;
		case 24:
			letter = "x";
			break;
		case 25:
			letter = "y";
			break;
		default:
			letter = "z";
	}
	if (Math.floor(Math.random() * 2) === 0) {
		letter = letter.toLocaleUpperCase();
	}
	return letter;
};

export {
	getTodaysDate,
	getWeekStart,
	getWeekEnd,
	dayInWeek,
	plusOneWeek,
	minusOneWeek,
	getWeek,
	jumpToToday,
	getWorkload,
	getWeekDates,
	getUserFolder,
	generateID,
};
