import React from "react";

/* COMPONENT LOCATIONS:  
  Router.js
*/
class NotFound extends React.Component {
	render() {
		return <div className="centered">PAGE NOT FOUND!!</div>;
	}
}

export default NotFound;
