import React from "react";
import { dayInWeek, getTodaysDate } from "../../helpers";
import { dayNames } from "../../arrays";

/* COMPONENT LOCATIONS:  
  Calendar.js
*/
class WeekDates extends React.Component {
	render() {
		return (
			<>
				{/* month year, week changer for future and past weeks, jump to today button */}
				<div className="days-of-the-week">
					{dayNames.map((day, index) => (
						<div
							className={`day ${
								//comparing the full date to todays date and adding a class if they match
								dayInWeek(index)[1] === getTodaysDate() ? "today" : ""
							}`}
							key={day.name}
						>
							<h3>
								{
									//displaying just the day number for that date
									dayInWeek(index)[0]
								}
							</h3>
							<p>{day.abr}</p>
						</div>
					))}
				</div>
			</>
		);
	}
}

export default WeekDates;
