import React from "react";
import { CSSTransition } from "react-transition-group";
import { PropTypes } from "prop-types"; //debugging tool
import AddProjectForm from "../forms/AddProjectForm";
import ListOfUserProjects from "./user/ListOfUserProjects";
import ProjectWeekTimeline from "./user/ProjectWeekTimeline";
import MaxHoursPerDayForm from "../forms/MaxHoursPerDayForm";
import { dataService } from "../../services/services";
import { getWorkload, getWeekDates, getUserFolder } from "../../helpers";

/* COMPONENT LOCATIONS:  
  ListOfUsers.js
*/
class User extends React.Component {
	// creating a state that is used only for each individual user
	state = {
		dropdown: false,
		projectForm: false,
		hoursForm: false,
		projects:
			this.props.user.projects !== undefined
				? Object.getOwnPropertyNames(this.props.user.projects)
				: null,
	};

	toggleProjectList = () => {
		this.setState({ dropdown: !this.state.dropdown });
	};

	// this function also exists in the ListOfUsers.js file, but is duplicated out of necessity as the function must exist in the same file as the states that are being changed or it will not work properly
	toggleForm = (form) => {
		this.setState({ [form]: !this.state[form] });
		if (!this.state[form]) {
			document.querySelector(".people-list").style.overflowY = "hidden";
			// document.querySelector("html").style.overflowY = "hidden";
		} else {
			document.querySelector(".people-list").style.overflowY = "scroll";
			// document.querySelector("html").style.overflowY = "visible";
		}
	};

	updatingProjectState = () => {
		// updating the state so the users ui gets updated in real time
		this.setState({
			projects:
				this.props.user.projects !== undefined
					? Object.getOwnPropertyNames(this.props.user.projects)
					: null,
		});
	};
	// adding the project data to the user
	addProjectToUser = (userID, projectName) => {
		//refractor
		dataService.addProjectToUser(userID, projectName);
		this.updatingProjectState();
	};
	// adding user data to the projects in the database
	addUserToProject = (userID, projectName) => {
		//refractor
		dataService.addUserToProject(userID, projectName);
		this.addProjectToUser(userID, projectName);
	};
	// checks to see if the user is already working on that project or not
	checkUsersAssignedProjects = (userID, projectName) => {
		// Refactor
		dataService.checkUsersAssignedProjects(
			userID,
			projectName,
			(assigned, userID, projectName) => {
				// if the user is not working on that project already then they are added to it
				if (!assigned) {
					this.addUserToProject(userID, projectName);
					console.log("added user project");
				} else {
					console.log("This user is already working on this project");
				}
			}
		);
	};
	addNewProject = (userID, projectDetails, projectName) => {
		// checks to see if there is already a project with that name
		dataService.getProject(projectName, (exists, data) => {
			//checks the database to see if the project name already has data stored in it
			if (!exists) {
				// adds a new project under the projects folder in the database
				dataService.updateProjects({ [`${projectName}`]: projectDetails });
				this.checkUsersAssignedProjects(userID, projectName);
				console.log("Project successfully created");
			} else {
				this.checkUsersAssignedProjects(userID, projectName);
				console.log("Project already created");
			}
			this.updatingProjectState();
		});
	};
	deleteProject = (projectName) => {
		const userID =
			this.props.user.userID === null || this.props.user.userID === undefined
				? this.props.user.email.split(".")[0]
				: this.props.user.userID;
		const confirmation = window.confirm(
			"Are you sure you want to remove this project from this user?"
		);
		if (confirmation === true) {
			let numUsers;
			dataService.getProject(projectName, (exists, data) => {
				// gets the number of users on the project
				numUsers = data.numberOfUsers;
				// makes sure there are users working o the project
				if (numUsers !== null && numUsers !== undefined) {
					if (numUsers > 0) {
						// decrements the number of users in the database
						dataService.incrementUsersPerProject(projectName, -1);
					}
					if (numUsers === 1 || numUsers <= 0) {
						// removes the project if there are no users working on it, or if the last person gets removed
						dataService.deleteProject(projectName);
					}
				}
				this.updatingProjectState();
			});

			dataService.deleteUserAndProjectData(userID, projectName);
			this.updatingProjectState();
		}
	};
	getTotalHoursWorking = () => {
		let total = 0;
		const projectList = this.state.projects;
		if (projectList) {
			projectList.map((projectName) => {
				dataService.getUserDaysWorking(
					getUserFolder(this.props.user),
					projectName,
					(exists, data) => {
						// 1. loop through days of the currently viewed week
						let weekDates = getWeekDates();
						weekDates.map((dateInCurrentWeek) => {
							// 2. if there is a date in the data base that matches one of those days get the value of that date
							if (data !== null && data !== undefined) {
								// debugger;

								if (
									data[dateInCurrentWeek] !== true &&
									data[dateInCurrentWeek] !== undefined
								) {
									// getting the hours working for that date from the database and making sure its a number , incase it is stored as a string
									const hours = Number.parseFloat(data[dateInCurrentWeek]);
									// 3. if the value is a number, add it to the total hours working for the week
									total = total + hours;
									return total;
								}
								// Object.keys(data).map((date) => {
								// 	if (date !== "working") {
								// 		debugger;
								// 	}
								// 	if (date === dateInCurrentWeek && data[date] !== true) {
								// 		// getting the hours working for that date from the database and making sure its a number , incase it is stored as a string
								// 		const hours = Number.parseFloat(data[date]);
								// 		// 3. if the value is a number, add it to the total hours working for the week
								// 		total = total + hours;
								// 		return total;
								// 	}
								// 	return total;
								// });
							}
							return total;
						});
					}
				);
				return total;
			});
		}
		return total;
	};
	render() {
		console.log(this.getTotalHoursWorking());
		return (
			<>
				<li
					className={
						this.state.dropdown
							? `expanded ${
									this.props.user.userID === this.props.userData.user.uid
										? "active-user"
										: ""
							  }`
							: this.props.user.userID === this.props.userData.user.uid
							? "active-user"
							: null
					}
				>
					<div onClick={this.toggleProjectList}>
						{this.props.user.photoURL !== undefined ? (
							<img
								src={`${this.props.user.photoURL}`}
								alt={`${this.props.user.name}`}
								className="profile-photos"
							/>
						) : (
							<div className="profile-photos photo-placeholder">
								<p>{this.props.user.name.charAt(0)}</p>
							</div>
						)}
						{/* displays different information based on the class name so this component can be used more than one place */}
						{this.props.class === "people-list" ? (
							<>
								<div className="person-info">
									<div>
										<p>{this.props.user.name}</p>
										<p
											className={
												getWorkload(
													this.props.user.hoursPerDay,
													this.getTotalHoursWorking()
												) <= 100
													? null
													: "overloaded"
											}
										>
											{getWorkload(
												this.props.user.hoursPerDay,
												this.getTotalHoursWorking()
											)}
											%
										</p>
									</div>

									<button
										className="dropdown-arrow"
										onClick={this.toggleProjectList}
									>
										{this.state.dropdown ? "↑" : "↓"}
									</button>
								</div>
							</>
						) : (
							<p>{this.props.user.name}</p>
						)}
					</div>
					<ListOfUserProjects
						projectForm={this.state.projectForm}
						dropdown={this.state.dropdown}
						toggleForm={this.toggleForm}
						user={this.props.user}
						projects={this.state.projects}
						deleteProject={this.deleteProject}
					/>
					<div className="absolute-position">
						{this.state.projectForm ? (
							<div
								className="form-background"
								onClick={() => this.toggleForm("projectForm")}
							></div>
						) : null}
						<CSSTransition
							in={this.state.projectForm}
							timeout={{ enter: 250, exit: 250 }}
							classNames="form"
							unmountOnExit
						>
							<AddProjectForm
								user={this.props.user}
								addNewProject={this.addNewProject}
								toggleForm={this.toggleForm}
							/>
						</CSSTransition>
					</div>
				</li>
				<ProjectWeekTimeline
					projectForm={this.state.projectForm}
					toggleProjectList={this.toggleProjectList}
					dropdown={this.state.dropdown}
					user={this.props.user}
					projects={this.state.projects}
					toggleTimePerDayForm={this.props.toggleTimePerDayForm}
				/>
				<div className="absolute-position">
					{this.state.hoursForm ? (
						<div
							className="form-background"
							onClick={() => this.toggleForm("hoursForm")}
						></div>
					) : null}
					<CSSTransition
						in={this.state.hoursForm}
						timeout={{ enter: 250, exit: 250 }}
						classNames="form"
						unmountOnExit
					>
						<MaxHoursPerDayForm
							toggleForm={this.toggleForm}
							user={this.props.user}
						/>
					</CSSTransition>
				</div>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: Router/PeopleView/ListOfUsers/
		usersList: PropTypes.array,
		// PROPS PATH: Router/PeopleView/ListOfUsers/
		user: PropTypes.object,
		// PROPS PATH: PeopleView/ListOfUsers/
		class: PropTypes.string,
	};
}

export default User;
