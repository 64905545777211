import React from "react";
import PropTypes from "prop-types"; //debugging tool
import { CSSTransition } from "react-transition-group";
import { dataService } from "../../services/services";
import { dayInWeek, getUserFolder } from "../../helpers";

/* COMPONENT LOCATIONS:  
  people-view/user/ProjectDayTimeline.js
*/
class TimePerDayForm extends React.Component {
	dailyHoursRef = React.createRef();
	state = {
		[dayInWeek(0)[2]]: 0.0,
		[dayInWeek(1)[2]]: 0.0,
		[dayInWeek(2)[2]]: 0.0,
		[dayInWeek(3)[2]]: 0.0,
		[dayInWeek(4)[2]]: 0.0,
		[dayInWeek(5)[2]]: 0.0,
		[dayInWeek(6)[2]]: 0.0,
	};

	componentDidMount() {
		// making sure the UI and the database information match
		this.getHours();
	}
	componentDidUpdate() {
		if (this.props.form) {
			this.dailyHoursRef.current.focus();
		}
	}
	updateState = (stateVal) => {
		const dayIndex =
			this.props.day === "saturday"
				? 0
				: this.props.day === "sunday"
				? 1
				: this.props.day === "monday"
				? 2
				: this.props.day === "tuesday"
				? 3
				: this.props.day === "wednesday"
				? 4
				: this.props.day === "thursday"
				? 5
				: 6;
		this.setState({
			[dayInWeek(dayIndex)[2]]: stateVal,
		});
	};

	getHours = () => {
		const dayIndex =
			this.props.day === "saturday"
				? 0
				: this.props.day === "sunday"
				? 1
				: this.props.day === "monday"
				? 2
				: this.props.day === "tuesday"
				? 3
				: this.props.day === "wednesday"
				? 4
				: this.props.day === "thursday"
				? 5
				: 6;

		dataService.getHours(
			getUserFolder(this.props.user),
			this.props.project,
			dayInWeek(dayIndex)[2],
			(exists, data) => {
				if (exists) {
					// setting the state so the UI matches the database
					this.updateState(data);
				}
			}
		);
	};

	setTimeForDay = (e, project, date) => {
		const userFolder = getUserFolder(this.props.user);
		const numHours = Number.parseFloat(this.dailyHoursRef.current.value);
		// prevents the form from refreshing the page
		e.preventDefault();
		// updated the working day based on the hours entered for that day
		if (numHours === 0) {
			dataService.updateDaysWorking(userFolder, project, { [date]: null });
		} else {
			dataService.updateDaysWorking(userFolder, project, {
				[date]: numHours,
			});
		}
		// getting the hours so the UI updates
		this.getHours();
		// hiding the from on submission
		this.props.toggleTimeForm();
		e.currentTarget.childNodes[1].reset();
	};

	render() {
		const dayIndex =
			this.props.day === "saturday"
				? 0
				: this.props.day === "sunday"
				? 1
				: this.props.day === "monday"
				? 2
				: this.props.day === "tuesday"
				? 3
				: this.props.day === "wednesday"
				? 4
				: this.props.day === "thursday"
				? 5
				: 6;
		const hoursForDay =
			this.state[dayInWeek(dayIndex)[2]] === true
				? "hours not set"
				: this.state[dayInWeek(dayIndex)[2]];
		return (
			<>
				{this.props.form ? (
					// creating a back overlay so the form is highlighted and so only one hour form can be open at once
					<div
						className="form-background"
						onClick={() => this.props.toggleTimeForm()}
					></div>
				) : null}
				<CSSTransition
					// this is triggered on the state of dropdown being true
					in={this.props.form}
					timeout={{ enter: 250, exit: 250 }}
					classNames="mini-popup"
					// unmountOnExit deletes the component from the HTML till its generated again when the profile picture is clicked
					unmountOnExit
				>
					<>
						<div
							className="time-per-day-form"
							onSubmit={(e) =>
								this.setTimeForDay(
									e,
									this.props.project,
									dayInWeek(dayIndex)[2]
								)
							}
						>
							<button
								onClick={() => this.props.toggleTimeForm()}
								className="close-btn"
							>
								+
							</button>
							<form onSubmit={() => console.log("form submitted")}>
								<label htmlFor="hours">
									Hours for <span>{this.props.projectName}</span> on{" "}
									{dayInWeek(dayIndex)[3]}
									<br /> (ex: 1.25 = 1h 15min)
								</label>
								<input
									type="number"
									ref={this.dailyHoursRef}
									name="hours"
									placeholder={hoursForDay}
									min={0}
									max={24}
									step={0.25}
									required
								/>
								<button type="submit">
									{this.props.working
										? this.state[this.props.day] !== 0
											? "update hours"
											: "set hours"
										: "set working hours"}
								</button>
							</form>
						</div>
					</>
				</CSSTransition>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: ProjectWeekTimeline/ProjectDayTimeline
		working: PropTypes.bool,
		// PROPS PATH: ProjectWeekTimeline/ProjectDayTimeline
		project: PropTypes.string,
		// PROPS PATH: ProjectWeekTimeline/ProjectDayTimeline
		day: PropTypes.string,
		// PROPS PATH: ProjectDayTimeline/ProjectDayTimeline
		form: PropTypes.bool,
		// PROPS PATH: ProjectDayTimeline/ProjectDayTimeline
		toggleTimeForm: PropTypes.func,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/ProjectWeekTimeline/ProjectDayTimeline/ProjectDayTimeline
		user: PropTypes.object,
	};
}

export default TimePerDayForm;
