import React from "react";
import { CSSTransition } from "react-transition-group";
import { PropTypes } from "prop-types"; //debugging tool

/* COMPONENT LOCATIONS:  
  Navigation.js
*/
class Profile extends React.Component {
	state = {
		menu: false,
	};
	toggleMenu = () => {
		// toggle the menu state between true (being shown) and false (not existing)
		this.setState({ menu: !this.state.menu });
	};
	render() {
		return (
			<div className="profile-wrapper">
				<button
					onClick={() => this.toggleMenu()}
					className="account-profile-picture"
				>
					<img
						// PROPS PATH: Router/(PeopleView or ProjectView)/Navigation
						src={`${this.props.userData.user.photoURL}`}
						alt={`${this.props.userData.user.displayName}`}
					/>
				</button>
				<CSSTransition
					// this is triggered on the state of menu being true
					in={this.state.menu}
					timeout={{ enter: 250, exit: 250 }}
					classNames="menu"
					// unmountOnExit deletes the component from the HTML till its generated again when the profile picture is clicked
					unmountOnExit
				>
					{/* PROPS PATH: Router/(PeopleView or ProjectView)/Navigation */}
					<button className="sign-out-btn" onClick={() => this.props.signOut()}>
						Sign Out
					</button>
				</CSSTransition>
			</div>
		);
	}
	static propTypes = {
		signOut: PropTypes.func,
		userData: PropTypes.object,
	};
}

export default Profile;
