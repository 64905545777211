import React from "react";
import { PropTypes } from "prop-types"; //debugging tool
import Navigation from "./navigation/Navigation";

/* COMPONENT LOCATIONS:  
  Router.js
*/
class ProjectView extends React.Component {
	render() {
		return (
			<>
				<Navigation
					path={this.props.match.path}
					userData={this.props.userData}
				/>
				<p className="centered">The project view is not currently available</p>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: Router/
		signOut: PropTypes.func,
		// PROPS PATH: Router/
		userData: PropTypes.object,
	};
}

export default ProjectView;
