import React from "react";
import { PropTypes } from "prop-types"; //debugging tool
import { dayNames } from "../../../arrays";
import ProjectDayTimeline from "./ProjectDayTimeline";
import { dataService } from "../../../services/services.tsx";
import { dayInWeek, getUserFolder } from "../../../helpers";

/* COMPONENT LOCATIONS:  
  User.js
*/
class ProjectWeekTimeline extends React.Component {
	state = {
		hoursPerDayFor: false,
	};
	getDaysForProject = (project, index) => {
		let workingDay;
		let backToBackDays;
		let userWorkingOnProject;
		const previousDay = index !== 0 ? dayInWeek(index - 1)[2] : dayInWeek(0)[2];
		const userFolder = getUserFolder(this.props.user);
		dataService.getUserDaysWorking(userFolder, project, (exists, data) => {
			// checks in the day exists
			if (data !== null) {
				// sets the working Day to the value of the day in the database
				workingDay = data[dayInWeek(index)[2]];
			}
			if (exists) {
				userWorkingOnProject = true;
			} else {
				userWorkingOnProject = false;
			}
			return userWorkingOnProject;
		});
		// checks if the user is working on the the project
		if (userWorkingOnProject) {
			dataService.getUserDaysWorking(userFolder, project, (exists, data) => {
				// checks in the day exists
				if (exists) {
					if (data !== null) {
						workingDay = data[dayInWeek(index)[2]] ? true : false;
						// console.log(project + " " + workingDay + " " + dayInWeek(index)[3]);
					}
					// checks if the previous days working value is the same as the current day in the loop
					if (
						workingDay === false ||
						data[previousDay]
						// workingDay === (index === 0 ? false : data[previousDay])
					) {
						backToBackDays = false;
					} else {
						backToBackDays = true;
					}
					if (
						dayInWeek(index)[2] === dayInWeek(index)[4] &&
						workingDay !== false
					) {
						backToBackDays = true;
					}
				}
			});
		}
		// returns two booleans [if the user is working that day, if the user is working the day right before the day the loop is on]
		return [workingDay, backToBackDays];
	};
	getWorkingDays = () => {
		// sets all the days to false by default
		let working = {
			[dayInWeek(1)[2]]: false,
			[dayInWeek(2)[2]]: false,
			[dayInWeek(0)[2]]: false,
			[dayInWeek(3)[2]]: false,
			[dayInWeek(4)[2]]: false,
			[dayInWeek(5)[2]]: false,
			[dayInWeek(6)[2]]: false,
		};
		if (this.props.projects !== null && this.props.projects.length > 1) {
			this.props.projects.map((project) => {
				dayNames.map((day, index) => {
					if (this.getDaysForProject(project, index)[0]) {
						// changes the days value based on the working days value from the getDaysForProject function
						working[dayInWeek(index)[2]] = true;
					}
					return working;
				});
				return working;
			});
			return working;
		}
		console.log(working);
		return working;
	};

	render() {
		return (
			<>
				<div className="user-project-timeline-wrapper">
					{/* this checks if the user is currently working on any projects or not then displays either the projects of default text */}
					{this.props.projects !== null ? (
						<ul>
							{this.props.dropdown || this.props.projects.length === 1 ? (
								<>
									{this.props.projects.map((project, index) => (
										<li key={project}>
											<>
												{dayNames.map((day, index) => (
													// creating a component so everyday has its own form
													<ProjectDayTimeline
														key={day.name}
														project={project}
														day={day}
														index={index}
														user={this.props.user}
														getDaysForProject={this.getDaysForProject}
														toggleTimePerDayForm={
															this.props.toggleTimePerDayForm
														}
													/>
												))}
											</>
										</li>
									))}
								</>
							) : (
								<li
									className="relative-position project-timeline"
									onClick={() => this.props.toggleProjectList()}
								>
									{dayNames.map((day, index) =>
										this.getWorkingDays()[dayInWeek(index)[2]] ? (
											<div
												key={day.name}
												className=" timeline-day working-day no-hover"
											></div>
										) : (
											<div
												key={day.name}
												className="timeline-day no-hover"
											></div>
										)
									)}
									<div className="multiple-projects">
										<p>
											Expand to view{" "}
											{
												// takes the users full name and only displays the first name
												`${this.props.user.name}`.split(" ")[0]
											}
											{"'s "}
											{this.props.projects.length} projects
										</p>
									</div>
								</li>
							)}
						</ul>
					) : (
						<p className="subtle-text">
							{
								// takes the users full name and only displays the first name
								`${this.props.user.name}`.split(" ")[0]
							}{" "}
							is not currently working on any projects
						</p>
					)}
				</div>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: User/
		toggleProjectList: PropTypes.func,
		// PROPS PATH: User/
		dropdown: PropTypes.bool,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/
		user: PropTypes.object,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/
		projects: PropTypes.array,
	};
}

export default ProjectWeekTimeline;
