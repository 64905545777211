import React from "react";
import PropTypes from "prop-types"; //debugging tool
import { dataService } from "../../services/services";
import { generateID, getUserFolder } from "../../helpers";

/* COMPONENT LOCATIONS:  
  people-view/User.js
*/
class AddProjectForm extends React.Component {
	//creates all the necessary refs via react default methods of createRef so we can use the input data again later
	state = {
		nameRef: "",
		IDRef: "",
		existingProjectList: [],
		existingProjectIDs: [],
	};
	descRef = React.createRef();
	jobNumRef = React.createRef();

	componentDidMount() {
		// gets a list of all the existing projects from the database and sets it to the state of existingProjectList
		//	so the user is able to select from a dropdown if they know that project has been created in the database already
		dataService.getProjects((exists, data) => {
			if (exists) {
				let projectNames = [];
				Object.getOwnPropertyNames(data).map((ID) => {
					projectNames.push(`${data[ID].name}`);
					return projectNames;
				});
				this.setState({
					existingProjectIDs: projectNames,
					existingProjectList: Object.getOwnPropertyNames(data),
				});
			}
		});
	}
	checkExistingProjects = () => {
		dataService.getProjects((exists, data) => {
			if (exists) {
				let projectExists = false;
				Object.getOwnPropertyNames(data).map((ID, index) => {
					console.log(index + " " + projectExists);
					if (
						data[ID].name.toLowerCase() ===
						this.state.nameRef.trimLeft().trimRight().toLowerCase()
					) {
						// sets the projectID so that there are not duplicates of projects
						// console.log("project already exists");
						this.setState({ IDRef: data[ID].ID });
						projectExists = true;
					}
					if (projectExists === false) {
						// removes the ID if the project name is not exactly the same (non-case sensitive)
						// console.log("project does not already exist");
						this.setState({ IDRef: "" });
					}
					return data[ID];
				});
			}
		});
	};
	// this function will create an new project in the database, or if the projects exists it will add the selected user to that project
	createProject = (e) => {
		const projectID = this.state.IDRef !== "" ? this.state.IDRef : generateID();
		console.log(projectID);
		// puts all the inputted data from the from into an object to be added to the database
		const projectDetails = {
			//pulls the reference and gets the value of that ref from the inputs
			name: this.state.nameRef.trimLeft().trimRight(),
			ID: projectID,
			desc: this.descRef.current.value.trimLeft().trimRight(),
			jobNumber: this.jobNumRef.current.value,
			numberOfUsers: 0,
		};
		// passing the updated and new props back up one level to be used there
		// PROPS PATH: User
		this.props.addNewProject(
			getUserFolder(this.props.user),
			projectDetails,
			// this.state.nameRef.toLowerCase().trimLeft().trimRight() // stores the name of the projects as lowercase so that the project cant be made multiple times with different letter cases
			projectID // stores the project under its generated ID
		);
		// clears the form on submission
		e.currentTarget.reset();
		// hides the form after it resets
		// PROPS PATH: User/
		this.props.toggleForm("projectForm");
	};
	// this function checks to see that the user has either selected an existing project or typed in a new project name before updating the database
	checkProjectSelect = (e) => {
		// prevents the page from refreshing
		e.preventDefault();
		if (this.state.IDRef === "" && this.state.nameRef === "") {
			alert("You have to select an existing project or create a new one");
		} else {
			//tests to see if the project has already been created
			this.createProject(e);
		}
	};
	render() {
		return (
			<div className="form form-small">
				<button
					// PROPS PATH: User/
					onClick={() => this.props.toggleForm("projectForm")}
					className="close-btn"
				>
					+
				</button>
				<form onSubmit={(e) => this.checkProjectSelect(e)}>
					<select
						value={this.state.IDRef}
						onChange={(e) => this.setState({ IDRef: e.target.value })}
					>
						<option value="">select an existing project . . .</option>
						{this.state.existingProjectList.map((projectID, index) => {
							return (
								<option key={projectID} value={projectID}>
									{this.state.existingProjectIDs[index]}
								</option>
							);
						})}
					</select>
					<h1 id="or"> or </h1>
					<input
						type="text"
						value={this.state.nameRef}
						onChange={(e) => {
							this.setState({ nameRef: e.target.value });
							this.checkExistingProjects();
						}}
						name="name"
						placeholder="Project Name"
					/>
					<textarea
						type="text"
						//" ref = " stores the value that was inputted by the user
						ref={this.descRef}
						name="desc"
						placeholder="Description"
					></textarea>
					<input
						type="text"
						// " ref = " stores the value that was inputted by the user
						ref={this.jobNumRef}
						name="image"
						placeholder="Job Number"
					/>
					<button type="submit">Add Project</button>
				</form>
			</div>
		);
	}
	static propTypes = {
		// PROPS PATH: User/
		toggleForm: PropTypes.func,
		// PROPS PATH: User/
		addNewProject: PropTypes.func,
	};
}

export default AddProjectForm;
