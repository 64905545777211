import React from "react";
import PropTypes from "prop-types"; //debugging tool
import { dataService } from "../../services/services";

/* COMPONENT LOCATIONS:  
  people-view/user/AssignedProject.js
*/
class EditProjectForm extends React.Component {
	//creates all the necessary refs via react default methods of createRef so we can use the input data again later
	state = {
		projectDesc: "",
		jobNumber: "",
		projectName: "",
	};

	componentDidMount() {
		//gets the project data from the database and sets it to the appropriate states so the user can see the current data
		dataService.getProject(this.props.name, (exists, data) => {
			if (exists) {
				this.setState({
					projectDesc: data.desc,
					jobNumber: data.jobNumber,
					projectName: data.name,
				});
			}
		});
	}
	//this function will take the changed data based on the user's inputs and apply them to the project in the database
	editProject = (e) => {
		e.preventDefault();
		const projectDetails = {
			//pulls the reference and gets the value of that ref from the inputs
			name: this.state.projectName,
			desc: this.state.projectDesc,
			jobNumber: this.state.jobNumber,
		};
		dataService.updateProject(this.props.name, projectDetails);
		//passing the updated and new props back up one level to be used there
		alert("project information updated");
		this.props.toggleForm("projectForm");
	};

	render() {
		return (
			<div className="form form-small">
				<button
					onClick={() => this.props.toggleForm("projectForm")}
					className="close-btn"
				>
					+
				</button>
				<form onSubmit={this.editProject}>
					<input
						type="text"
						//sets the state value as the value of the input to allow for editing of the data
						value={this.state.projectName}
						// when the user changes anything in the input the state value is updated to reflect that so the value of the input is displayed and stored correctly
						onChange={(e) => this.setState({ projectName: e.target.value })}
						name="image"
						placeholder="set a job number"
					/>
					<textarea
						type="text"
						//sets the state value as the value of the input to allow for editing of the data
						value={this.state.projectDesc}
						// when the user changes anything in the input the state value is updated to reflect that so the value of the input is displayed and stored correctly
						onChange={(e) => this.setState({ projectDesc: e.target.value })}
						name="desc"
						placeholder="type a description"
					></textarea>
					<input
						type="text"
						//sets the state value as the value of the input to allow for editing of the data
						value={this.state.jobNumber}
						// when the user changes anything in the input the state value is updated to reflect that so the value of the input is displayed and stored correctly
						onChange={(e) => this.setState({ jobNumber: e.target.value })}
						name="image"
						placeholder="set a job number"
					/>
					<button type="submit">Update Project</button>
				</form>
			</div>
		);
	}
	static propTypes = {
		// PROPS PATH: ListOfUsers/User/ListOfProjects/AssignedProject/
		name: PropTypes.string,
		// PROPS PATH: Router/(PeopleView or ProjectView)/ListOfUsers/User/ListOfProjects/AssignedProject/
		user: PropTypes.object,
		// PROPS PATH: User
		toggleForm: PropTypes.func,
	};
}

export default EditProjectForm;
