import RealTimeDatabaseStrategy from "./realtime-database";
import env from "react-dotenv";
import nodemailer from "nodemailer";

export interface InitialUserData {
	userID: string;
	name: string;
	photoURL: string;
	email: string;
	hoursPerDay: number;
}
// Class to call the generic functions with
export class DataService {
	// Property for the Strategy in use
	private strategy: DatabaseStrategy;

	// Constructor
	constructor(strategy: DatabaseStrategy) {
		this.strategy = strategy;
	}

	public setStrategy(strategy: DatabaseStrategy) {
		this.strategy = strategy;
	}

	// Functions that do what we want them to do by calling the methods on the strategy
	// ===== get functions
	getUsers(onUserUpdate: UserUpdateFunction) {
		this.strategy.getUsers(onUserUpdate);
	}
	getUserDaysWorking(
		userID: string,
		projectName: string,
		onSnapshotData: onSnapshotDataFunction
	) {
		this.strategy.getUserDaysWorking(userID, projectName, onSnapshotData);
	}
	getProject(projectName: string, onSnapshotData: onSnapshotDataFunction) {
		this.strategy.getProject(projectName, onSnapshotData);
	}
	getHours(
		userID: string,
		projectName: string,
		day: string,
		onSnapshotData: onSnapshotDataFunction
	) {
		this.strategy.getHours(userID, projectName, day, onSnapshotData);
	}
	getProjects(onSnapshotData: onSnapshotDataFunction) {
		this.strategy.getProjects(onSnapshotData);
	}

	// ===== set functions
	initialStoreUser(
		userID: string,
		emailID: string,
		userData: InitialUserData,
		onSnapshotData: onSnapshotDataFunction
	) {
		this.strategy.initialStoreUser(userID, emailID, userData, onSnapshotData);
	}
	setEmailIDToUserID(userID: string, emailID: string, projectName: string) {
		this.strategy.setEmailIDToUserID(userID, emailID, projectName);
	}
	inviteUser(
		emailID: string,
		emailAddress: string,
		data: Object,
		usersList: Array<any>
	) {
		this.strategy.inviteUser(emailID, emailAddress, data, usersList);
	}

	// ===== update functions
	updateDaysWorking(userID: string, projectName: string, data: Object) {
		this.strategy.updateDaysWorking(userID, projectName, data);
	}
	updateHours(userID: string, data: Object) {
		this.strategy.updateHours(userID, data);
	}
	updateUserAndProjectData(
		userID: string,
		projectName: string,
		newData: Object
	) {
		this.strategy.updateUserAndProjectData(userID, projectName, newData);
	}
	updateProjects(newData: Object) {
		this.strategy.updateProjects(newData);
	}
	incrementUsersPerProject(projectName: string, incrementVal: number) {
		this.strategy.incrementUsersPerProject(projectName, incrementVal);
	}
	updateProject(projectName: string, newData: Object) {
		this.strategy.updateProject(projectName, newData);
	}

	// ===== add functions
	addProjectToUser(userID: string, projectName: string) {
		this.strategy.addProjectToUser(userID, projectName);
	}
	addUserToProject(userID: string, projectName: string) {
		this.strategy.addUserToProject(userID, projectName);
	}

	// ===== compare functions
	checkUsersAssignedProjects(
		userID: string,
		projectName: string,
		onAssignedProject: onAssignedProjectFunction
	) {
		this.strategy.checkUsersAssignedProjects(
			userID,
			projectName,
			onAssignedProject
		);
	}

	// ===== delete functions
	deleteUserAndProjectData(userID: string, projectName: string) {
		this.strategy.deleteUserAndProjectData(userID, projectName);
	}
	deleteProject(projectName: string) {
		this.strategy.deleteProject(projectName);
	}
}

// class to send invites, named incantationService because summoning someone to your app is way cooler than just inviting them
export class IncantationService {
	/* this service does NOT get called but remains incase it is used in the future */
	private transporter = nodemailer.createTransport({
		host: "smtp.gmail.com",
		port: 465,
		secure: true, // true for 465, false for other ports
		auth: {
			user: env.INVITE_EMAIL,
			pass: env.INVITE_PASSWORD,
		},
	});
	public sendEmailInvite = (email: string) => {
		console.log("invited " + email);

		// create reusable transporter object using the default SMTP transport
		this.transporter;

		// send mail with defined transport object
		let info = {
			from: `chronos.invite@paradowski.com`, // sender address
			to: `${email}`, // list of receivers
			subject: "Chronos Resource Scheduler Invite", // Subject line
			text: "Join Chronos", // plain text body
			html: "<a href='https://chronos-944ef.web.app/people'>https://chronos-944ef.web.app/people</a>", // html body
		};
		// console.log(info);
		// console.log(this.transporter);
		// transporter.sendMail(info, (error, data) => {
		// 	if (error) {
		// 		console.log("error occurred", error);
		// 	} else {
		// 		console.log("email sent");
		// 	}
		// });
	};
}

//props functions
export type UserUpdateFunction = (user: Object) => any;
export type onAssignedProjectFunction = (
	assigned: boolean,
	userID: string,
	projectName: string
) => any;
export type onSnapshotDataFunction = (exists: boolean, data: Object) => any;

export interface DatabaseStrategy {
	// ===== get functions
	getUsers(onUserUpdate: UserUpdateFunction): any;
	getUserDaysWorking(
		userID: string,
		projectName: string,
		onSnapshotData: onSnapshotDataFunction
	): any;
	getProject(projectName: string, onSnapshotData: onSnapshotDataFunction): any;
	getHours(
		userID: string,
		projectName: string,
		day: string,
		onSnapshotData: onSnapshotDataFunction
	): any;
	getProjects(onSnapshotData: onSnapshotDataFunction): any;

	// ===== set functions
	initialStoreUser(
		userID: string,
		emailID: string,
		userData: InitialUserData,
		onSnapshotData: onSnapshotDataFunction
	): any;
	setEmailIDToUserID(
		userID: string,
		emailID: string,
		projectName: string
	): void;
	inviteUser(
		emailID: string,
		emailAddress: string,
		data: Object,
		usersList: Array<any>
	): void;

	// ===== update functions
	updateDaysWorking(userID: string, projectName: string, data: Object): void;
	updateHours(userID: string, data: Object): void;
	updateUserAndProjectData(
		userID: string,
		projectName: string,
		newData: Object
	): void;
	updateProjects(newData: Object): void;
	incrementUsersPerProject(projectName: string, incrementVal: number): void;
	updateProject(projectName: string, newData: Object): void;

	// ===== add functions
	addProjectToUser(userID: string, projectName: string): void;
	addUserToProject(userID: string, projectName: string): void;

	// ===== compare functions
	checkUsersAssignedProjects(
		userID: string,
		projectName: string,
		onAssignedProject: onAssignedProjectFunction
	): any;

	// ===== delete functions
	deleteUserAndProjectData(userID: string, projectName: string): void;
	deleteProject(projectName: string): void;
}

const dataService: DataService = new DataService(
	new RealTimeDatabaseStrategy()
);
const incantationService: IncantationService = new IncantationService();
export { dataService, incantationService };
