import React from "react";
import PropTypes from "prop-types"; //debugging tool
import { CSSTransition } from "react-transition-group";
import User from "./User";
import InviteUserForm from "../forms/InviteUserForm";

/* COMPONENT LOCATIONS:  
  PeopleView.js
*/
class ListOfUsers extends React.Component {
	state = {
		invitationForm: false,
	};
	// this function also exists in the User.js file, but is duplicated out of necessity as the function must exist in the same file as the states that are being changed or it will not work properly
	toggleForm = (form) => {
		this.setState({ [form]: !this.state[form] });
		if (!this.state[form]) {
			document.querySelector(".people-list").style.overflowY = "hidden";
			// document.querySelector("html").style.overflowY = "hidden";
		} else {
			document.querySelector(".people-list").style.overflowY = "scroll";
			// document.querySelector("html").style.overflowY = "visible";
		}
	};
	render() {
		const userList = this.props.usersList ? this.props.usersList : [];
		return (
			<>
				<div className={`${this.props.class}`}>
					<ul className="user-list">
						{/* creates a user component for every user that exists */}
						{userList.map((user) => (
							<React.Fragment key={user.key}>
								<User
									// passing all the necessary props to the user component
									userData={this.props.userData}
									usersList={this.props.usersList}
									class={this.props.class}
									user={user}
									toggleTimePerDayForm={this.props.toggleTimePerDayForm}
								/>
							</React.Fragment>
						))}
						<li className="centered">
							{/* invite user */}
							<button
								className="new-project-btn"
								onClick={() => this.toggleForm("invitationForm")}
							>
								invite new user
							</button>
						</li>
						<div className="user-project-timeline-wrapper"></div>
					</ul>
				</div>
				<CSSTransition
					//this is triggered on the state of dropdown being true
					in={this.state.invitationForm}
					timeout={{ enter: 250, exit: 250 }}
					classNames="mini-popup"
					// unmountOnExit deletes the component from the HTML till its generated again when the profile picture is clicked
					unmountOnExit
				>
					<>
						<div
							className="form-background"
							onClick={() => this.toggleForm("invitationForm")}
						></div>
						<InviteUserForm
							toggleForm={this.toggleForm}
							usersList={this.props.usersList}
							userData={this.props.userData}
						/>
					</>
				</CSSTransition>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: PeopleView/
		class: PropTypes.string,
		// PROPS PATH: Router/PeopleView/
		usersList: PropTypes.array,
		// PROPS PATH: Router/PeopleView/
		userData: PropTypes.object,
		// PROPS PATH: Router/PeopleView/
		subtractWeek: PropTypes.func,
		// PROPS PATH: Router/PeopleView/
		weekOf: PropTypes.string,
		// PROPS PATH: Router/PeopleView/
		addWeek: PropTypes.func,
		// PROPS PATH: Router/PeopleView/
		today: PropTypes.func,
	};
}

export default ListOfUsers;
