import React from "react";
import { PropTypes } from "prop-types"; //debugging tool
import TimePerDayForm from "../../forms/TimePerDayForm";
import { dataService } from "../../../services/services";

/* COMPONENT LOCATIONS:  
  people-view/user/ProjectWeekTimeline.js
*/
class ProjectDayTimeline extends React.Component {
	state = {
		hoursPerDayForm: false,
		projectName: "",
	};

	toggleTimeForm = () => {
		this.setState({ hoursPerDayForm: !this.state.hoursPerDayForm });
	};
	componentDidMount() {
		//gets the project data from the database and sets it to the appropriate states so the user can see the current data
		dataService.getProject(this.props.project, (exists, data) => {
			if (exists) {
				this.setState({
					projectName: data.name,
				});
			}
		});
	}
	render() {
		return (
			<>
				<div className="relative-position">
					<TimePerDayForm
						day={this.props.day.name}
						project={this.props.project}
						projectName={this.state.projectName}
						user={this.props.user}
						form={this.state.hoursPerDayForm}
						toggleTimeForm={this.toggleTimeForm}
						working={
							this.props.getDaysForProject(
								this.props.project,
								this.props.index
							)[0]
						}
					/>

					<div
						className={
							this.props.getDaysForProject(
								this.props.project,
								this.props.index
							)[0]
								? "timeline-day working-day"
								: "timeline-day"
						}
						onClick={() => this.toggleTimeForm()}
					>
						<p>
							{this.props.getDaysForProject(
								this.props.project,
								this.props.index
							)[1]
								? this.state.projectName
								: " "}
						</p>
					</div>
				</div>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: ProjectWeekTimeline/
		getDaysForProject: PropTypes.func,
		// PROPS PATH: ProjectWeekTimeline/
		index: PropTypes.number,
		// PROPS PATH: ProjectWeekTimeline/
		day: PropTypes.object,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/ProjectWeekTimeline/
		user: PropTypes.object,
		// PROPS PATH: ProjectWeekTimeline/
		project: PropTypes.string,
	};
}

export default ProjectDayTimeline;
