import React from "react";
import { CSSTransition } from "react-transition-group";
import { PropTypes } from "prop-types"; //debugging tool
import AssignedProject from "./AssignedProject";

/* COMPONENT LOCATIONS:  
  people-view/User.js
*/
class ListOfUsersProjects extends React.Component {
	render() {
		return (
			<>
				<div className="dropdown-wrapper">
					<CSSTransition
						// this is triggered on the state of dropdown being true
						in={this.props.dropdown}
						timeout={{ enter: 250, exit: 250 }}
						classNames="dropdown"
						// unmountOnExit deletes the component from the HTML till its generated again when the profile picture is clicked
						unmountOnExit
					>
						<div className="dropdown">
							<>
								{/* this checks if the user is currently working on any projects or not then displays either the projects of default text */}
								{this.props.projects !== null ? (
									this.props.projects.map((project) => (
										<AssignedProject
											project={project}
											user={this.props.user}
											key={project}
											deleteProject={this.props.deleteProject}
										/>
									))
								) : (
									<p>
										{
											// takes the users full name and only displays the first name
											`${this.props.user.name}`.split(" ")[0]
										}{" "}
										is not currently working on any projects
									</p>
								)}
							</>
							<button
								className="new-project-btn"
								onClick={() => this.props.toggleForm("hoursForm")}
							>
								Edit Hours <br /> currently set to {this.props.user.hoursPerDay}{" "}
								hours per day
							</button>
							<button
								className="new-project-btn"
								onClick={() => this.props.toggleForm("projectForm")}
							>
								+ Project
							</button>
						</div>
					</CSSTransition>
				</div>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: User/
		toggleForm: PropTypes.func,
		// PROPS PATH: User/
		deleteProject: PropTypes.func,
		// PROPS PATH: User/
		dropdown: PropTypes.bool,
		// PROPS PATH: User/
		projectForm: PropTypes.bool,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/
		user: PropTypes.object,
		// PROPS PATH: User/
		projects: PropTypes.array,
	};
}

export default ListOfUsersProjects;
