import React from "react";
import PropTypes from "prop-types"; //debugging tool
import { dataService /* incantationService */ } from "../../services/services";

/* COMPONENT LOCATIONS:  
  people-view/ListOfUsers.js
*/
class InviteUserForm extends React.Component {
	//creates all the necessary refs via react default methods of createRef so we can use the input data again later
	nameRef = React.createRef();
	emailRef = React.createRef();

	// this was going to be implemented but has be put on pause indefinably for now
	// sendEmailInvite = (email) => {
	// 	incantationService.sendEmailInvite(email);
	// };

	//this function adds a user to the database by there emailID so they can be assigned projects and hours (the emailID get replaced when that user first signs in)
	inviteUser = (e) => {
		e.preventDefault();
		const email = this.emailRef.current.value
			.trimLeft()
			.trimRight()
			.split(".")[0];
		const emailAddress = this.emailRef.current.value.trimLeft().trimRight();
		const usersList = this.props.usersList;
		//setting a temporary storage location ion the database for the added user until they sign in themselves
		dataService.inviteUser(
			email,
			emailAddress,
			{
				//pulls the reference and gets the value of that ref from the inputs
				name: this.nameRef.current.value,
				email: emailAddress,
				hoursPerDay: 8,
			},
			usersList
		);
		// this.sendEmailInvite(emailAddress); this would send an email with a link to the site automatically
		//clears the form
		e.currentTarget.reset();
		//hides the project form
		this.props.toggleForm("invitationForm");
	};

	render() {
		return (
			<div className="form form-invite">
				<button
					onClick={() => this.props.toggleForm("invitationForm")}
					className="close-btn"
				>
					+
				</button>
				<h1>Invite a user</h1>
				<form onSubmit={this.inviteUser}>
					{/* " ref = " stores the value that was inputted by the user */}
					<input
						type="email"
						ref={this.emailRef}
						name="email"
						placeholder="email"
						required
					/>
					<input
						type="text"
						ref={this.nameRef}
						name="name"
						placeholder="name"
						required
					/>

					<button type="submit">invite user</button>
				</form>
			</div>
		);
	}
	static propTypes = {
		// PROPS PATH: ListOfUsers/
		toggleForm: PropTypes.func,
		// PROPS PATH: Router/PeopleView/ListOfUsers/User/ListOfProjects/AssignedProject/
		usersList: PropTypes.array,
	};
}

export default InviteUserForm;
