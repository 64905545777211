import React from "react";
import PropTypes from "prop-types"; //debugging tool
import Navigation from "./navigation/Navigation";
import ListOfUsers from "./people-view/ListOfUsers";
import Calendar from "./calender/Calendar";

/* COMPONENT LOCATIONS:  
  Router.js
*/
class PeopleView extends React.Component {
	render() {
		return (
			<>
				<Navigation
					path={this.props.match.path}
					signOut={this.props.signOut}
					userData={this.props.userData}
				/>
				<div className="content-wrapper">
					<Calendar
						subtractWeek={this.props.subtractWeek}
						weekOf={this.props.weekOf}
						addWeek={this.props.addWeek}
						today={this.props.today}
					/>
					<ListOfUsers
						class={"people-list"}
						usersList={this.props.usersList}
						userData={this.props.userData}
						subtractWeek={this.props.subtractWeek}
						weekOf={this.props.weekOf}
						addWeek={this.props.addWeek}
						today={this.props.today}
						toggleTimePerDayForm={this.toggleTimePerDayForm}
					/>
				</div>
			</>
		);
	}
	static propTypes = {
		// PROPS PATH: Router/
		signOut: PropTypes.func,
		// PROPS PATH: Router/
		userData: PropTypes.object,
		// PROPS PATH: Router/
		usersList: PropTypes.array,
		// PROPS PATH: Router/
		subtractWeek: PropTypes.func,
		// PROPS PATH: Router/
		weekOf: PropTypes.string,
		// PROPS PATH: Router/
		addWeek: PropTypes.func,
		// PROPS PATH: Router/
		today: PropTypes.func,
	};
}

export default PeopleView;
